@use '../../includes/' as *;
@use 'sass:color';

.auth_code_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100vw - #{$g48});
  max-width: 550px;
  text-align: center;
}

.auth_code_input__headline {
  @include h1;

  margin-block-end: $g12;
}

.auth_code_input__text {
  @include body-copy-1;

  max-width: 440px;
  margin-block-end: $g24;
}

.auth_code_input__device_list {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: $g8 $g24;
  max-width: 440px;
  margin-block-end: $g28;

  li {
    display: inline;
    margin: 0;
    padding: 0;
    color: $pebble;
    font-size: clamp($f13, 1vw + 0.5em, $f16);
    font-weight: $medium;
  }
}

.auth_code_input__form {
  display: flex;
  position: relative;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: clamp(36px, 7vw + 0.5em, 60px);
  margin-block-end: $g16;
}

.auth_code_input__input {
  flex-grow: 1;
  width: auto;
  padding-inline-start: clamp($g16, 8vw + 0.5em, $g28);
  border: 0;
  border-start-start-radius: $g4;
  border-start-end-radius: 0;
  border-end-start-radius: $g4;
  border-end-end-radius: 0;
  background-color: color.mix($white, $midnight, 20%);
  color: $white;
  font-size: clamp($f16, 2vw + 0.5em, $f22);
  text-transform: uppercase;

  &:focus-visible {
    outline: $focusstatelight;
  }

  &::placeholder {
    color: $moon;
    text-transform: none;
  }
}

.auth_code_input__button {
  @include btn($white);
  @include fill-btn($white);

  & {
    justify-content: center;
    width: clamp(50px, 30%, 170px);
    border-start-start-radius: 0;
    border-start-end-radius: 6px;
    border-end-start-radius: 0;
    border-end-end-radius: 6px;
    font-size: clamp($f14, 2vw + 0.5em, $f22);
  }
}

.auth_code_error {
  position: absolute;
  inset-block-start: calc(100% + #{$g16});
  width: 100%;
  color: $coral;
  font-size: clamp($f13, 1vw + 0.5em, $f14);
  line-height: 1.5;
  text-align: center;

  a {
    transition: opacity var(--duration-half) ease-in;
    color: currentcolor;
    font-weight: $bold;
    text-decoration: underline;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}
