@use '../../includes' as *;

.passport_faq {
  max-width: 846px;
  margin: auto;
  margin-block: var(--spacing-block);
  padding-inline: var(--spacing-inline);
}

.passport_faq_header {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: $g12;

  @include breakpoint($sm) {
    align-items: center;
  }
}

.passport_faq_header_title {
  @include h2;
}

.passport_faq_header_description {
  @include body-copy-3;

  @include breakpoint($sm) {
    @include body-copy-2;
  }

  svg {
    height: 0.75em;
    margin-inline-start: 2px;
    transform: translateY(5%);
    fill: $fog;
  }

  a {
    display: block;
    margin-inline-start: 0;
    padding-block-start: $g8;
    font-weight: $bold;

    &:hover,
    &:focus-visible,
    &:active {
      svg {
        fill: var(--link-active-color);
      }
    }
  }

  @include breakpoint($sm) {
    a {
      display: inline;
      margin-inline-start: $g12;
    }
  }
}

.passport_faq_list {
  margin-block-start: clamp($g28, 1.2vw + 1em, $g40);
}
