.app_logo {
  height: auto;
  margin-block-end: 4%;

  &.ios {
    width: 7.2%;
  }

  &.apple_tv {
    width: 8.4%;
  }

  &.roku {
    width: 13.3%;
  }

  &.android {
    width: 25%;
  }

  &.fire_tv {
    width: 10.4%;
  }

  &.chromecast {
    width: 26.9%;
  }

  &.android_tv {
    width: 21.9%;
  }

  &.samsung {
    width: 23.2%;
  }

  &.vizio {
    width: 15.5%;
  }
}
