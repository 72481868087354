@use '../../includes/' as *;

.activate_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100vw - #{$g48});
  max-width: 400px;
}

.activate_success_content_top {
  display: flex;
  flex-direction: column;
  gap: $g8;
  padding-block-end: calc(var(--spacing-block) * 0.5);
  border-block-end: 1px solid $blue-gray;

  h2 {
    @include h1;
  }

  p {
    @include body-copy-1;
  }
}

.activate_success_content_bottom {
  @include body-copy-2($moon);

  display: flex;
  flex-direction: column;
  gap: $g8;
  padding-block-start: calc(var(--spacing-block) * 0.5);

  a {
    transition: opacity var(--duration-half) ease-in;
    color: $yellow;
    font-weight: $bold;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}

.activate_success_passport_lockup {
  color: $white;
}
