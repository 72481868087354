@use '../../includes' as *;

.passport_lockup {
  display: inline-flex;
  align-items: center;
  text-wrap: nowrap;

  &:is(h1) {
    @include h1;
  }

  &:is(h2) {
    @include h2;
  }

  &:is(h3) {
    @include h3;
  }

  &:is(h4) {
    @include h4;
  }

  &:is(h5) {
    @include h5;
  }

  &:is(h6, p) {
    @include h6;
  }
}

.passport_compass_rose_icon {
  // Using ems here because we want the text to be 60% of the icon's height.
  // 1.42 is eyeballing this based on figma
  // This icon is inside of the h1, so by using ems, the icon will scale up and down
  // with the size of the text, but the size is tied to the h1's text size.
  // See https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units#relative_length_units
  width: 1.42em;
  height: 1.42em;
  margin-inline: 0.2em;
}
